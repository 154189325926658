import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import { BlogPagination } from "../../components/pagination"
import { BlogList } from "../../components/blog-list"

const BlogPage = ({ data, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const tags = data.allMicrocmsTag.edges.map(x => x.node)
  const categories = data.allMicrocmsCategory.edges.map(x => x.node)
  return (
    <Layout title={siteTitle} tags={tags} categories={categories}>
      <BlogList blogs={data.allMicrocmsBlog.edges}/>
      <BlogPagination totalCount={data.allMicrocmsBlog.totalCount} current={pageContext.current} />
    </Layout>
  )
}

export default BlogPage

export const Head = () => <Seo title="過去の記事" description="うすかんがたまに執筆するウェブ技術・セキュリティ・ガジェットなどのブログです。"/>

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMicrocmsBlog(sort: {fields: date, order: DESC}, limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          blogId
          title
          date
          publishedAt
          thumbnail {
            url
          }
          description
          tags {
            name
          }
          category {
            name
          }          
        }
      }
    }
    allMicrocmsCategory(limit: 30, skip:0) {
      totalCount
      edges {
        node {
          categoryId
          name
          blogsCount
        }
      }
    }
    allMicrocmsTag(limit: 100, skip:0) {
      totalCount
      edges {
        node {
          tagId
          name
          blogsCount
        }
      }
    }
  }
`